<template>
	<div class="bg_dark">
		<div class="container">
			<div class="row">
				<div class="pt-80">
					<div class="intro_logo_title">
						<span class="hide">문화예술 전용 커뮤니티 마피아 프로젝트</span>
					</div>
				</div>
				<div class="mt-30 intro_btn_wrap">
					<div class="social_login">

						<input
							v-model="item.login_id"
							placeholder="아이디를 입력하세요"
							style="border: 1px solid #ddd; padding: 10px 20px; display: block; width: 100%; border-radius: 20px; background-color: white; font-size: 14px"
						>

						<input
							v-model="item.login_paswd"
							type="password"
							placeholder="비밀번호를 입력하세요"
							style="border: 1px solid #ddd; padding: 10px 20px; display: block; width: 100%; border-radius: 20px; background-color: white; font-size: 14px"
							class="mt-30"
						>

						<button
							class="btn_l btn_id mt-30"
							@click="postLogin"
						>로그인</button>
					</div>

					<a @click="$emit('to', { name: 'mafia004'})" class="btn_signup">회원가입</a>
					<p class="copyright">Copyright © MAFI Inc. </p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mafia0111'
		, props: []
		, data: function(){
			return {
				program: {
					name: 'id login'
					, title: 'ID 로그인'
					, not_header: true
					, not_footer: true
				}
				, item: {
					login_id: ''
					, login_paswd: ''
				}
			}
		}
		, methods: {
			postLogin: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: 'mafiaa/mem/login'
						, data: {
							login_id: this.item.login_id
							, login_paswd: this.item.login_paswd
						}
						, type: true
					})

					if(result.success){
						this.token_info = result.data
						// console.log('this.token_info', this.token_info)

						sessionStorage.removeItem('Error')
						if(result.data.session_token){
							await this.$encodeStorage.setSessionToken(result.data.session_token)
						}

						let e = {
							type: 'id'
							, access_token: result.data.session_token
						}

						result.data.member_state_code = 'MB00500005'

						switch (result.data.member_state_code){
							default:
								this.$emit('to', {name: 'mafia009'})
								break;
							case 'MB00500001':
								this.$emit('to', {name: 'mafia0101'})
								break;
							case 'MB00500002':
								this.$bus.$emit('onPin', 'join')
								break;
							case 'MB00500003':
								this.$emit('to', {name: 'nice'})
								break;
							case 'MB00500004':
								this.$emit('to', {name: 'sms'})
								break;
							case 'MB00500005':
								if(result.data.session_token){
									await this.$encodeStorage.setSessionToken(result.data.session_token)
								}
								await this.$encodeStorage.setItem('login_info', e)
								await this.$encodeStorage.setSessionAt(e)
								this.$bus.$emit('setUser', result.data)
								await this.$router.push({ name: 'main'})
								break;
							case 'MB00500006':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'stop', msg: result.message}})
								break;
							case 'MB00500007':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'out', msg: result.message}})
								break;
							case 'MB00500008':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'sleep', msg: result.message}})
								break;
						}
					}else{
						console.log(result)
						if((result.code).indexOf('E0014') > -1){
							await this.$encodeStorage.removeItem('login_info')
							this.$bus.$emit('to', { name: 'mafia009', params: {type: 'auth', msg: result.message}})
						}else {

							switch (result.code) {
								case 'E000500001':
									this.$bus.$emit('to', { name: 'mafia009', params: {type: 'not', msg: result.message}})
									break;
								case 'E000400012':
									this.$bus.$emit('to', { name: 'mafia009', params: {type: 'stop', msg: result.message}})
									break;
								case 'E000400013':
									this.$bus.$emit('to', { name: 'mafia009', params: {type: 'out', msg: result.message}})
									break;
								case 'E000400014':
									this.$bus.$emit('to', { name: 'mafia009', params: {type: 'sleep', msg: result.message}})
									break;
								case 'E001400003':
								case 'E001400004':
								case 'E001400008':
									await this.$encodeStorage.removeItem('login_info')
									throw '인증이 만료되었습니다. 다시 로그인하여 주세요'
								default:
									await this.$encodeStorage.removeItem('login_info')
									throw result.message
							}
						}
					}
				}catch (e) {
					console.log(e)
					this.error = e

					this.$emit('to', { name: 'mafia009', params: { type: 'third', msg: e}})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, onPin: function(pin_type){
				this.$bus.$emit('onPin', pin_type)
			}
		}
		, created() {
			this.$emit('onLoad', this.program)
		}
	}
</script>